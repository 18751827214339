import * as Sentry from '@sentry/browser';
import { HomeFilled, LeftCircleOutlined, MenuOutlined, RightCircleOutlined } from '@ant-design/icons';
import { Avatar, Button, Menu, Row } from 'antd';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { logout, RootState } from '../../redux';
import { CustomStepKeyedByJourneyStageId } from '../../redux/reducers/CustomStepsReducer';
import { UserInformation, UserProfile } from '../../repos';
import { Colors } from '../../theme';
import { Analytics, AnalyticsEventName } from '../../utils';
import Styles from './Navbar.module.sass';
import { getBarOffsetForCurrentRoute, UserJourneyBar } from './UserJourneyBar';
import { navigate } from '@reach/router';
import { logo } from '../../assets';
import Cookies from 'js-cookie';

interface Props {
  userInformation?: UserInformation;
  currentUser?: UserProfile | null;
  customSteps: CustomStepKeyedByJourneyStageId;
  logout: typeof logout;
}

enum MenuItem {
  Logout = 'Logout',
}

const Navbar: React.FC<Props> = (props) => {
  const { logout, userInformation, currentUser, customSteps } = props;

  let userJourneyBarContainer: HTMLDivElement | null = null;

  useEffect(() => {
    const offset = getBarOffsetForCurrentRoute();
    if (!userJourneyBarContainer) {
      return;
    }
    userJourneyBarContainer.scrollLeft = offset;
  });
  if (process.env.REACT_APP_NO_NAV) {
    return (
      <Row align="middle" className={Styles.nonav}>
        <Menu mode="horizontal">
          <Menu.SubMenu
            key={'user-menu'}
            style={{ width: '100%' }}
            title={
              <div style={{ display: 'flex' }}>
                <MenuOutlined />
              </div>
            }>
            {[MenuItem.Logout].map((item) => {
              return (
                <Menu.Item
                  onClick={() => {
                    switch (item) {
                      case MenuItem.Logout:
                        Analytics.log(AnalyticsEventName.UserLogoutClicked);
                        Sentry.setUser(null);

                        logout();
                        break;
                      default:
                        break;
                    }
                  }}
                  key={item}>
                  {item}
                </Menu.Item>
              );
            })}
          </Menu.SubMenu>
        </Menu>
        <img src={logo} alt="logo" />
      </Row>
    );
  }

  return (
    <div className={Styles.navbarContainer}>
      {currentUser?.onboardingCompleted && (
        <Row
          onClick={() => {
            navigate!('/');
          }}
          className={Styles.homeContainer}>
          <HomeFilled />
        </Row>
      )}
      <div
        className={Styles.userJourneyBarContainer}
        style={{
          visibility: currentUser && currentUser.onboardingCompleted ? 'visible' : 'hidden',
        }}>
        <Button
          type="link"
          onClick={() => {
            userJourneyBarContainer!.scrollLeft -= 180;
          }}
          style={{ padding: 0, height: 26 }}>
          <LeftCircleOutlined style={{ fontSize: 24, color: Colors.GreyDark, marginRight: 8 }} />
        </Button>
        <div
          className={Styles.journeyBarContainer}
          ref={(ref) => (userJourneyBarContainer = ref)}
          style={{
            overflow: 'hidden',
          }}>
          <UserJourneyBar customSteps={customSteps} />
        </div>
        <Button
          type="link"
          onClick={() => {
            userJourneyBarContainer!.scrollLeft += 180;
          }}
          style={{ padding: 0, height: 26 }}>
          <RightCircleOutlined style={{ fontSize: 24, color: Colors.GreyDark, marginLeft: 8 }} />
        </Button>
      </div>
      <Menu mode="horizontal" style={{ borderBottom: 'none', width: '100px' }}>
        <Menu.SubMenu
          key={'user-menu'}
          style={{ width: '100%' }}
          title={
            <div style={{ display: 'flex' }}>
              <Avatar size={40} style={{ backgroundColor: Colors.Primary600, margin: '0 auto' }}>
                {(userInformation && userInformation.firstName.charAt(0).toUpperCase()) || (currentUser && currentUser.name.charAt(0).toUpperCase()) || 'G'}
              </Avatar>
            </div>
          }>
          {[MenuItem.Logout].map((item) => {
            return (
              <Menu.Item
                onClick={() => {
                  switch (item) {
                    case MenuItem.Logout:
                      Analytics.log(AnalyticsEventName.UserLogoutClicked);

                      Sentry.setUser(null);
                      logout();
                      break;
                    default:
                      break;
                  }
                }}
                key={item}>
                {item}
              </Menu.Item>
            );
          })}
        </Menu.SubMenu>
      </Menu>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { userInformation } = state.Profile;
  const { currentUser } = state.Auth;
  const { steps } = state.CustomSteps;
  return { userInformation, currentUser, customSteps: steps };
};

export default connect(mapStateToProps, { logout })(Navbar);
