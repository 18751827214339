import React from 'react';
import { Button, Card, Col, Row } from 'antd';
import Styles from './WaiverGuide.module.sass';
import { bookIcon, stampImage, waiverAutomationProcessing } from '../../assets';
import { InsuranceDocs } from './InsuranceDocs';
import { SolutionOutlined, MessageOutlined, SelectOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-duotone-svg-icons';
import { Alert, AlertType, InstructionalModal, IntercomAPI } from '../../components';
import { InsuranceRequestGBGType, InsuranceRequestWellAwayType } from '../../repos';

interface WaiverGuideProps {
  waiverGuide: string | undefined;
  insuranceData: InsuranceRequestGBGType | InsuranceRequestWellAwayType | undefined;
}

const WaiverGuide: React.FC<WaiverGuideProps> = (props) => {
  const { insuranceData, waiverGuide } = props;
  const [showModal, setShowModal] = React.useState(false);

  if (insuranceData && insuranceData.cardLongURL)
    return (
      <Row gutter={[24, 12]} justify="center" className={Styles.guideContainer}>
        {waiverGuide !== 'automated' && (
          <Col xs={24} lg={waiverGuide === 'NA' ? 24 : 14}>
            {waiverGuide === 'NA' && (
              <Alert
                type={AlertType.Info}
                message="Waiver guide is not available for your university. For more details contact your university."
                style={{ marginBottom: 48 }}
              />
            )}

            <InsuranceDocs insuranceData={insuranceData} hideTitle />
          </Col>
        )}
        {waiverGuide !== 'NA' ? (
          waiverGuide === 'automated' ? (
            <Row justify="center" className={Styles.automation}>
              <h1>
                {insuranceData.status === 'WaiverApplicationApproved'
                  ? `Your waiver application has been submitted.`
                  : `Sit back and relax. Your waiver application is in progress.`}
              </h1>
              <h2>
                {insuranceData.status === 'WaiverApplicationApproved'
                  ? `You will receive an email within 30 minutes from ${insuranceData.university} confirming your waiver approval.`
                  : `You will receive an email from us upon the submission of your waiver.`}
              </h2>
              {insuranceData.status === 'WaiverApplicationApproved' ? (
                <FontAwesomeIcon icon={faCircleCheck} />
              ) : (
                <img src={waiverAutomationProcessing} alt="Your waiver is processing"></img>
              )}
              <Col span={24} className={Styles.helpButtons}>
                <span>Need help?</span>
                <Button
                  type="primary"
                  onClick={() => {
                    IntercomAPI.showWithMessagePreFilled(
                      `Hey! I have question about ${insuranceData.university} insurance waiver application`,
                      false,
                    );
                  }}>
                  <MessageOutlined />
                  Chat with us
                </Button>
              </Col>
            </Row>
          ) : (
            <>
              <Col className={Styles.guide} xs={24} lg={10}>
                <InstructionalModal
                  visible={showModal}
                  setVisible={setShowModal}
                  type={'article'}
                  url={waiverGuide || ''}
                  title={
                    <>
                      <SolutionOutlined /> Waiver guide for {insuranceData.university}
                    </>
                  }
                  chatText={'Chat with us'}
                  intercomMsg={`Hey! I have questions about my waiver guide for ${insuranceData.university}`}
                />

                <Card
                  className={Styles.cardsContainer}
                  title={
                    <Row justify="start">
                      <Col span={24}>
                        <img src={bookIcon} alt="ID Card"></img>
                      </Col>
                      <Col span={24}>
                        <h2>Download the Waiver Guide</h2>
                      </Col>
                      <Col span={24}>
                        <h3>Follow this step-by-step guide to file your waiver.</h3>
                      </Col>
                    </Row>
                  }>
                  <ul
                    onClick={() => {
                      setShowModal(true);
                    }}>
                    <li>
                      <SelectOutlined /> Click here to open the Waiver Guide
                    </li>
                  </ul>
                </Card>
              </Col>
              <Col span={24} className={Styles.helpButtons}>
                <span>Need help?</span>
                <Button
                  type="primary"
                  onClick={() => {
                    IntercomAPI.showWithMessagePreFilled(`Hey! I have question about my Insurance documents`, false);
                  }}>
                  <MessageOutlined />
                  Chat with an Insurance Expert
                </Button>
              </Col>
            </>
          )
        ) : null}
      </Row>
    );
  else
    return (
      <Row justify="center" align="middle" className={Styles.awaiting}>
        <Col span={14}>
          <h1>Processing your insurance request now...</h1>
          <h2>
            We are processing your insurance request right now. You will soon receive an email with your insurance
            documents.
          </h2>
        </Col>
        <Col span={10}>
          <img alt={'Stamp'} src={stampImage}></img>
        </Col>
      </Row>
    );
};

export default WaiverGuide;
