import { RouteComponentProps } from '@reach/router';
import { InfoCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, DatePicker, Input, InputNumber, Radio, Tooltip, Typography, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import moment, { Moment } from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useEffectOnce, useMount } from 'react-use';
import { submitSopIllustration } from '../../assets';
import { GREInput, UniversityInput } from '../../components';
import { RootState, submitSopForm } from '../../redux';
import { University, UserSopVersion, UserTestScoreInformation } from '../../repos';
import { Analytics, AnalyticsEventName } from '../../utils';
import Styles from './SubmitSopForm.module.sass';

const SOP_FORM_DATA_LOCAL_STORAGE_KEY = 'submitSopFormData';
interface SubmitSopFormProps extends RouteComponentProps {
  submitSopForm: typeof submitSopForm;
  submittingSop?: boolean;
  sopVersion?: UserSopVersion;
  userTestScoreInformation?: UserTestScoreInformation;
}

function disabledDate(current?: Moment): boolean {
  if (!current) {
    return false;
  }
  return current < moment().subtract(1, 'day').endOf('day');
}

const useUnmount = (fn: () => any): void => {
  const fnRef = useRef(fn);
  fnRef.current = fn;
  useEffectOnce(() => () => fnRef.current());
};

const SubmitSopForm: React.FC<SubmitSopFormProps> = (props) => {
  const { submittingSop, submitSopForm, sopVersion, userTestScoreInformation } = props;

  const [sop = sopVersion && sopVersion.text, setSop] = useState<string>();
  const [collectingGRE = !!(userTestScoreInformation && userTestScoreInformation.gre), setCollectingGRE] = useState();
  const [
    greScore = userTestScoreInformation && userTestScoreInformation.gre && userTestScoreInformation.gre[0],
    setGreScore,
  ] = useState<{ verbal: number; quant: number; writing: number }>();
  const [greDate = sopVersion && sopVersion.greDate, setGreDate] = useState<Moment>();
  const [collectingTOEFL = !!(userTestScoreInformation && userTestScoreInformation.toefl), setCollectingTOEFL] =
    useState();
  const [
    toeflScore = userTestScoreInformation && userTestScoreInformation.toefl && userTestScoreInformation.toefl[0].score,
    setToeflScore,
  ] = useState<number | undefined>();
  const [toeflScoreError = false, setToeflScoreError] = useState<boolean>();
  const [toeflDate = sopVersion && sopVersion.toeflDate, setToeflDate] = useState<Moment>();
  const [universities = (sopVersion && sopVersion.universities) || [], setUniversities] = useState<University[]>();
  const [sopHelp = sopVersion && sopVersion.takingPaidHelp ? 'Yes' : 'No', setSopHelp] = useState();
  const [loanStatus, setLoanStatus] = useState<string>();
  const [sopDeadlineDate = sopVersion && sopVersion.submissionDeadline, setSopDeadlineDate] = useState<Moment>();
  const [formFillingStarted, setFormFillingStarted] = useState(false);
  const [resumeFile, setResumeFile] = useState<RcFile>();

  const useBeforeUnload = (fn: () => void) => {
    useEffect(() => {
      const beforeUnload = (e: any) => {
        fn();
      };

      window.addEventListener('beforeunload', beforeUnload);
      return () => window.removeEventListener('beforeunload', beforeUnload);
    }, [fn]);
  };
  const saveFormInLocalStorage = () => {
    if (formFillingStarted) {
      window.localStorage.setItem(
        SOP_FORM_DATA_LOCAL_STORAGE_KEY,
        JSON.stringify({
          sop,
          collectingGRE,
          greScore,
          greDate,
          collectingTOEFL,
          toeflScore,
          toeflScoreError,
          toeflDate,
          universities,
          sopHelp,
          loanStatus,
          sopDeadlineDate,
          formFillingStarted,
        }),
      );
    }
  };
  useBeforeUnload(saveFormInLocalStorage);
  useUnmount(saveFormInLocalStorage);

  useMount(() => {
    const dataString = window.localStorage.getItem(SOP_FORM_DATA_LOCAL_STORAGE_KEY);
    if (dataString && !sopVersion) {
      const dataObject = JSON.parse(dataString);
      setSop(dataObject.sop);
      setCollectingGRE(dataObject.collectingGRE);
      setGreScore(dataObject.greScore);
      setGreDate(dataObject.greDate ? moment(dataObject.greDate) : undefined);
      setCollectingTOEFL(dataObject.collectingTOEFL);
      setToeflScore(dataObject.toeflScore);
      setToeflScoreError(dataObject.toeflScoreError);
      setToeflDate(dataObject.toeflDate ? moment(dataObject.toeflDate) : undefined);
      setUniversities(dataObject.universities);
      setSopHelp(dataObject.sopHelp);
      setLoanStatus(dataObject.loanStatus);
      setSopDeadlineDate(dataObject.sopDeadlineDate ? moment(dataObject.sopDeadlineDate) : undefined);
      setFormFillingStarted(dataObject.formFillingStarted);
    }
  });

  const sendAnalyticsLogForFormFillingStarted = () => {
    if (!formFillingStarted) {
      Analytics.log(AnalyticsEventName.SOPRequestReviewFormStarted);
      setFormFillingStarted(true);
    }
  };

  return (
    <div className={Styles.container}>
      <div aria-disabled={sopVersion ? 'true' : 'false'} className={Styles.form}>
        <div className={Styles.header}>
          In this step, you'll submit your SOP so that we can review it and provide you with actionable feedback
        </div>
        <div className={[Styles.title, Styles.important].join(' ')}>👉 Copy and paste your SOP</div>
        <Input.TextArea
          disabled={!!sopVersion}
          className={Styles.sopInput}
          value={sop}
          onChange={(e) => {
            sendAnalyticsLogForFormFillingStarted();
            setSop(e.target.value);
          }}
          autoSize={{ minRows: 4, maxRows: 30 }}
          placeholder="Paste your SOP here..."
        />
        <div className={[Styles.title, Styles.important].join(' ')}>Have you taken the GRE yet?</div>
        <Radio.Group
          disabled={!!sopVersion}
          defaultValue={collectingGRE}
          value={collectingGRE}
          onChange={(e) => {
            setCollectingGRE(e.target.value);
            sendAnalyticsLogForFormFillingStarted();
          }}>
          <Radio value={true}>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
        {collectingGRE ? (
          <div style={{ flexDirection: 'column' }}>
            <div className={[Styles.title, Styles.important].join(' ')}>Provide your GRE Score</div>
            <GREInput
              disabled={!!sopVersion}
              defaultValue={greScore}
              onChange={(greScore) => {
                greScore && sendAnalyticsLogForFormFillingStarted();
                setGreScore(greScore);
              }}
            />
          </div>
        ) : (
          <div style={{ flexDirection: 'column' }}>
            <div className={[Styles.title, Styles.important].join(' ')}>When is your GRE date?</div>
            <DatePicker
              className={Styles.dateInput}
              disabled={!!sopVersion}
              placeholder="Select your GRE date"
              allowClear
              value={greDate}
              format="Do MMMM YYYY"
              onChange={(selectedDate) => {
                sendAnalyticsLogForFormFillingStarted();
                setGreDate(selectedDate || undefined);
              }}
              disabledDate={(currentDate) => {
                if (currentDate && currentDate.isBefore(moment(), 'day')) {
                  return true;
                }
                return false;
              }}
            />
          </div>
        )}

        <div className={[Styles.title, Styles.important].join(' ')}>Have you taken TOEFL yet?</div>
        <Radio.Group
          disabled={!!sopVersion}
          value={collectingTOEFL}
          defaultValue={collectingTOEFL}
          onChange={(e) => {
            setCollectingTOEFL(e.target.value);
            sendAnalyticsLogForFormFillingStarted();
          }}>
          <Radio value={true}>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
        {collectingTOEFL ? (
          <div style={{ flexDirection: 'column' }}>
            <div className={[Styles.title, Styles.important].join(' ')}>What is your TOEFL Score?</div>
            <InputNumber
              defaultValue={toeflScore}
              disabled={!!sopVersion}
              value={toeflScore}
              max={120}
              placeholder={'--'}
              onChange={(value: any) => {
                sendAnalyticsLogForFormFillingStarted();
                setToeflScore(value);
                value && (value > 120 || value < 0) ? setToeflScoreError(true) : setToeflScoreError(false);
              }}
              style={{ height: 'auto' }}
            />
            {toeflScoreError && (
              <Typography.Text style={{ marginTop: 8, marginLeft: 8 }} type="danger">
                Please enter a valid score.
              </Typography.Text>
            )}
          </div>
        ) : (
          <div style={{ flexDirection: 'column' }}>
            <div className={[Styles.title, Styles.important].join(' ')}>When is your TOEFL date?</div>
            <DatePicker
              className={Styles.dateInput}
              disabled={!!sopVersion}
              placeholder="When is your TOEFL date?"
              allowClear
              value={toeflDate}
              format="Do MMMM YYYY"
              onChange={(selectedDate) => {
                sendAnalyticsLogForFormFillingStarted();
                setToeflDate(selectedDate || undefined);
              }}
              disabledDate={(currentDate) => {
                if (currentDate && currentDate.isBefore(moment(), 'day')) {
                  return true;
                }
                return false;
              }}
            />
          </div>
        )}

        <div className={[Styles.title, Styles.important].join(' ')}>
          Which universities are you applying to?
          <span>
            &nbsp;
            <Tooltip title="If you don't know your final university list, just list the universities you're currently interested in applying to.">
              <InfoCircleOutlined />
            </Tooltip>
          </span>
        </div>
        <UniversityInput
          disabled={!!sopVersion}
          defaultSelectedUniversities={universities}
          onSelect={(universities) => {
            sendAnalyticsLogForFormFillingStarted();
            setUniversities(universities);
          }}
        />
        <div className={[Styles.title, Styles.important].join(' ')}>
          What is the deadline by which do you need to submit this SOP?
        </div>
        <DatePicker
          disabled={!!sopVersion}
          className={Styles.dateInput}
          allowClear={false}
          onChange={(date) => {
            sendAnalyticsLogForFormFillingStarted();
            date && setSopDeadlineDate(date.startOf('day'));
          }}
          format="Do MMMM YYYY"
          value={sopDeadlineDate}
          disabledDate={disabledDate}
        />
        <div className={Styles.title}>Are you paying an individual or a consultancy to help you with your SOP?</div>
        <Radio.Group
          disabled={!!sopVersion}
          defaultValue={sopHelp}
          options={['Yes', 'No']}
          value={sopHelp}
          onChange={(e) => {
            sendAnalyticsLogForFormFillingStarted();
            setSopHelp(e.target.value);
          }}
        />
        <div className={[Styles.title, Styles.important].join(' ')}>
          Have you started your loan application process?
        </div>
        <Radio.Group
          disabled={!!sopVersion}
          defaultValue={loanStatus}
          options={['Yes', 'No', 'Not applying for a loan']}
          value={loanStatus}
          onChange={(e) => {
            sendAnalyticsLogForFormFillingStarted();
            setLoanStatus(e.target.value);
          }}
        />
        <div className={Styles.title}>
          Upload your resume
          <span>
            &nbsp;
            <Tooltip title={'Uploading your resume allows us to give you better feedback on your SOP'}>
              <InfoCircleOutlined />
            </Tooltip>
          </span>
        </div>
        <Upload
          disabled={!!sopVersion}
          multiple={false}
          beforeUpload={(file) => {
            setResumeFile(file);
            sendAnalyticsLogForFormFillingStarted();
            return false;
          }}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
        <Button
          disabled={!!sopVersion}
          loading={submittingSop}
          onClick={() => {
            window.localStorage.removeItem(SOP_FORM_DATA_LOCAL_STORAGE_KEY);
            setFormFillingStarted(false);
            submitSopForm(
              sop,
              greScore,
              greDate,
              toeflScore,
              toeflDate,
              universities,
              sopHelp,
              loanStatus,
              sopDeadlineDate,
              resumeFile,
            );
          }}
          type="primary"
          className={Styles.submit}>
          Submit
        </Button>
      </div>
      <img src={submitSopIllustration} />
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { submittingSop, sopVersion, userTestScoreInformation } = state.SopReview;
  return { submittingSop, sopVersion, userTestScoreInformation };
};

export default connect(mapStateToProps, { submitSopForm })(SubmitSopForm);
