import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { CheckCircleFilled, FileTextOutlined, RightOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Alert, AlertProps, AskAIButton } from '../../components';
import { LockModal, markStepsStatus, PredefinedStep, StepStatus } from '../../repos';
import Styles from './Accordion.module.sass';
import { Row } from 'antd';
import { MarkAsCompleteButton } from '../Buttons';
import { LockedStateModal } from '../CommonModals';
import { CohereButton } from '../Buttons/CohereCallButton';
import Cohere from 'cohere-js';
import { useMobile } from '../../services';

export interface AccordionItemPublicProps {
  readonly status: StepStatus | string;
  readonly expanded?: boolean;
  readonly highlighted?: boolean;
  readonly destroyOnClose?: boolean;
  readonly showMarkAsComplete?: { uid: string; route: string[]; metadata?: any };
  readonly lockModal?: LockModal;
  readonly showCohere?: boolean;
  readonly showAI?: boolean;
}

interface AccordionItemProps extends AccordionItemPublicProps {
  readonly title: string;
  readonly statusText?: string | React.ReactElement;
  readonly icon?: string | React.ReactElement;
  lockOnComplete?: boolean;
  blurOnComplete?: boolean;
  readonly step?: PredefinedStep;
  readonly onExpanded?: () => void;
  readonly onStepClick?: () => void;
  readonly alert?: AlertProps;
  readonly footer?: React.ReactNode;
}

const AccordionItem: React.FC<AccordionItemProps> = (props) => {
  const {
    children,
    title,
    status,
    statusText = getStatusText(status),
    destroyOnClose,
    icon,
    expanded,
    onExpanded,
    onStepClick,
    highlighted,
    alert,
    footer,
    showMarkAsComplete,
    step,
    lockModal,
    showCohere,
    showAI,
  } = props;

  const [showLockModal, setShowLockModal] = useState(false);
  const myRef = useRef<HTMLDivElement>(null);
  const isMobile = useMobile();
  const renderIcon = () => {
    if (status === StepStatus.Complete)
      return <CheckCircleFilled aria-label={StepStatus.Complete} className={Styles.check} />;
    if (!icon) {
      return <FileTextOutlined className={Styles.icon} />;
    }
    if (typeof icon === 'string') {
      return <LegacyIcon className={Styles.icon} type={icon} />;
    }
    return icon;
  };
  useEffect(() => {
    if (expanded) {
      myRef!.current!.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }, [expanded]);

  return (
    <div
      ref={myRef}
      aria-expanded={expanded ? 'true' : 'false'}
      aria-pressed={highlighted ? 'true' : 'false'}
      aria-label={status}
      className={`${Styles.item} ${props.lockOnComplete && status === StepStatus.Complete ? ' all-disabled' : ''}`}>
      {lockModal && <LockedStateModal visible={showLockModal} setVisible={setShowLockModal} {...lockModal} />}
      <div
        aria-expanded={expanded ? 'true' : 'false'}
        aria-pressed={highlighted ? 'true' : 'false'}
        onClick={() => {
          if (props.lockOnComplete && status === StepStatus.Complete) return;
          if (status === StepStatus.Locked && lockModal) {
            setShowLockModal(true);
            return;
          }
          if (!onExpanded) {
            return;
          }
          onExpanded();
          if (onStepClick) onStepClick();
        }}
        className={Styles.header}>
        <div className={Styles.iconContainer}>{renderIcon()}</div>
        <div className={Styles.title}>{title}</div>
        {step && step.label && step.label[step.status || StepStatus.Pending] && (
          <div aria-label={status} className={Styles.label}>
            {step.label[step.status || StepStatus.Pending]}
          </div>
        )}
        <div aria-label={status} className={Styles.statusText}>
          {expanded && showCohere && (
            <CohereButton
              onClick={(e) => {
                e.stopPropagation();
                Cohere.makeCall();
              }}
              style={{ marginRight: 24 }}
            />
          )}
          {expanded && showAI && <AskAIButton route={step?.route} />}
          {showMarkAsComplete && expanded && !isMobile && status !== StepStatus.Complete && (
            <MarkAsCompleteButton
              style={{ marginRight: 24 }}
              onClick={(e) => {
                e.preventDefault();
                markStepsStatus(
                  showMarkAsComplete.uid,
                  showMarkAsComplete.route,
                  StepStatus.Complete,
                  showMarkAsComplete.metadata,
                );
                onExpanded && onExpanded();
              }}
              status={status}
              uid={showMarkAsComplete.uid}
              route={[]}
            />
          )}
          {statusText}
        </div>
        <RightOutlined
          aria-expanded={expanded ? 'true' : 'false'}
          aria-label={status}
          className={Styles.expandIndicator}
        />
        {isMobile ? (
          <div className={Styles.mButtonContainer}>
            {showMarkAsComplete && expanded && status !== StepStatus.Complete && (
              <MarkAsCompleteButton
                style={{ marginTop: 12 }}
                onClick={(e) => {
                  e.preventDefault();
                  markStepsStatus(
                    showMarkAsComplete.uid,
                    showMarkAsComplete.route,
                    StepStatus.Complete,
                    showMarkAsComplete.metadata,
                  );
                  onExpanded && onExpanded();
                }}
                status={status}
                uid={showMarkAsComplete.uid}
                route={[]}
              />
            )}
          </div>
        ) : null}
      </div>
      <div
        aria-expanded={expanded ? 'true' : 'false'}
        className={`${Styles.expandedContent}  ${
          props.blurOnComplete && status === StepStatus.Complete ? ' all-disabled' : ''
        } ${expanded ? ' animate__animated animate__fadeIn' : Styles.displayNone}`}
        style={footer ? { paddingBottom: 0 } : undefined}>
        {alert && <Alert className={Styles.alert} {...alert} />}
        {(expanded || !destroyOnClose) &&
          React.Children.map(children, (child) => {
            return React.cloneElement(child as any, { ...props });
          })}
      </div>
      {footer && expanded && <Row className={Styles.footer}>{footer}</Row>}
    </div>
  );
};

interface AccordionProps {
  style?: CSSProperties;
  className?: string;
  defaultExpanded?: number;
  onExpanded?: (index: number) => void;
}

interface AccordionState {
  expanded?: number;
}

const getStatusText = (status: StepStatus | string) => {
  switch (status) {
    case StepStatus.Complete:
      return 'Step complete!';
    case StepStatus.Processing:
      return 'In Progress';
    case StepStatus.ReadyForReview:
      return 'Ready for Review';
    case StepStatus.Locked:
      return 'Locked';
    case StepStatus.Incomplete:
    case StepStatus.Pending:
      return 'Open';
    default:
      return status;
  }
};

export class Accordion extends React.Component<AccordionProps, AccordionState> {
  static Item = AccordionItem;

  state = {
    expanded: this.props.defaultExpanded,
  };

  componentWillUpdate(nextProps: AccordionProps) {
    if (this.state.expanded !== nextProps.defaultExpanded) {
      this.setState({ expanded: this.props.defaultExpanded });
    }
  }

  render() {
    const { style, children, className, defaultExpanded, onExpanded } = this.props;
    const { expanded = defaultExpanded } = this.state;
    return (
      <div style={style} className={className ? `${className} ${Styles.accordion}` : Styles.accordion}>
        {React.Children.map(children, (child, index) => {
          return React.cloneElement(child as any, {
            expanded: index === expanded,
            highlighted: expanded === undefined || defaultExpanded === -1 ? true : expanded === index,
            onExpanded: () => {
              if (!onExpanded) {
                if (index === expanded) {
                  this.setState({ expanded: undefined });
                  return;
                }

                this.setState({ expanded: index });
                return;
              }
              onExpanded(index);
            },
          });
        })}
      </div>
    );
  }
}
