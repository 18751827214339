import { message } from 'antd';
import Cookies from 'js-cookie';
import moment from 'moment';
import { Moment } from 'moment';
import { setSelectedPlan } from '../../redux';
import { store } from '../../redux/store';
import { PlanType, University, uStates } from '../../repos';
import { FirestoreReference, reportSentryError } from '../../utils';
import { VisaType } from '../../redux/reducers/InsuranceReducer';

export const getOPTPlans = async (setPlans: (arg0: PlanType[]) => void) => {
  const optPlansDocs = await FirestoreReference.InsurancePlans().where('visaType', '==', VisaType.OPT).get();
  const optPlans = [];
  for (const planDoc of optPlansDocs.docs) {
    const plan = planDoc.data() as PlanType;
    optPlans.push({ ...plan, planID: planDoc.id });
  }
  setPlans(optPlans);
};

export const getPlans = (university: University, setPlans: (arg0: PlanType[]) => void) => {
  Cookies.set('universityID', university.id, {
    path: '/',
    domain: window.location.hostname.includes('localhost') ? 'localhost' : '.gradly.us',
  });
  const plans = university.plans.map(async (p) => {
    const planData = (await FirestoreReference.InsurancePlan(p.planID).get()).data() as PlanType;
    if (p.premiums) {
      for (const uniPremium of p.premiums) {
        let override = false;
        for (const planPremium of planData.premiums) {
          // If minAge and maxAge are same
          if (uniPremium.minAge === planPremium.minAge && uniPremium.maxAge === planPremium.maxAge) {
            override = true;
            planPremium.retailPrice = uniPremium.retailPrice || planPremium.retailPrice;
            planPremium.retailPriceHC = uniPremium.retailPriceHC || planPremium.retailPriceHC;
            planPremium.insuranceCompanyPrice = uniPremium.insuranceCompanyPrice || planPremium.insuranceCompanyPrice;
            planPremium.insuranceCompanyPriceHC =
              uniPremium.insuranceCompanyPriceHC || planPremium.insuranceCompanyPriceHC;
          }
        }
        if (!override) {
          planData.premiums.push(uniPremium);
        }
      }
    }
    return { ...planData, planID: p.planID };
  });
  Promise.all(plans).then((data) => {
    const splan = store.getState().Insurance.selectedPlan;
    if (splan && !data.map((p) => p.planID).includes(splan.planID)) {
      message.error({
        content: `${splan.retailName} is not available for ${university.name}. Please select a different plan.`,
        key: 'not_available',
        duration: 3,
      });
      Cookies.remove('planID', { domain: window.location.hostname.includes('localhost') ? 'localhost' : '.gradly.us' });
      store.dispatch(setSelectedPlan());
    }
    setPlans(data);
  });
};

export const calPremiumWithData = (
  dob: string,
  university?: University,
  planData?: PlanType,
  startDate?: Moment,
  endDate?: Moment,
) => {
  if (!(university && planData)) return -1;
  const age = moment().diff(moment(dob, 'YYYY-MM-DD'), 'years');
  const destinationState = university.gbgDestinationState;
  let premium = planData.premiums.filter((p) => p.minAge <= age && p.maxAge >= age)[0];

  const p = planData.visaType === VisaType.OPT ? planData : university.plans.find((p) => p.planID === planData.planID);
  if (!p) {
    reportSentryError(new Error(`Unable to find plan with ID ${planData.planID} from ${university.name}`));
    return -1;
  }
  if (p.premiums) {
    premium = p.premiums.filter((p) => p.minAge <= age && p.maxAge >= age)[0];
  }
  if (!premium) {
    reportSentryError(
      new Error(`Unable to find primum for age ${age} with plan ID ${planData.planID} from ${university.name}`),
    );
    return -1;
  }
  let annualPremiumAmount = 0;
  if (planData.insuranceCompany === 'GBG' && startDate && endDate && destinationState) {
    if (['FL', 'PA', 'IL', 'NJ', 'NY'].includes(uStates[destinationState])) {
      annualPremiumAmount = premium.retailPriceHC;
    } else annualPremiumAmount = premium.retailPrice;
    return (annualPremiumAmount * endDate.add(1, 'd').diff(startDate, 'd')) / 365;
  } else {
    if (!planData.isACA && startDate && endDate) {
      annualPremiumAmount = premium.retailPrice;
      return (annualPremiumAmount * endDate.add(1, 'd').diff(startDate, 'd')) / 365;
    }
    annualPremiumAmount = premium.retailPrice;
    return annualPremiumAmount;
  }
};

export const calculateInsurancePremium = async (
  dob: string,
  universityID: string,
  planID: string,
  startDate?: Moment,
  endDate?: Moment,
) => {
  const university = (await FirestoreReference.Universities().doc(universityID).get()).data() as University;
  const planData = (await FirestoreReference.InsurancePlan(planID).get()).data() as PlanType;
  return calPremiumWithData(dob, university, { ...planData, planID }, startDate, endDate);
};
