export const Environment = {
  env: process.env.REACT_APP_PROJECT_ENV || 'dev',
  algolia: {
    appId: process.env.REACT_APP_ALGOLIA_APP_ID || '',
    apiKey: process.env.REACT_APP_ALGOLIA_API_KEY || '',
    environment: process.env.REACT_APP_PROJECT_ENV || '',
  },
  intercom: {
    appId: process.env.REACT_APP_INTERCOM_ID || '',
  },
  api: {
    baseUrl: process.env.REACT_APP_API_URL || '',
  },
  redirectUri: `${process.env.REACT_APP_REDIRECT_URL}/verify-link`,
  cohereKey: process.env.REACT_APP_COHERE_KEY || '',
  useEmulator: process.env.REACT_APP_USE_EMULATOR || false,
};
