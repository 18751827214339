import { LockFilled, MessageFilled } from '@ant-design/icons';
import { Button, Modal, Row } from 'antd';
import React from 'react';
import { LockModal } from '../../repos';
import { IntercomAPI } from '../Intercom';
import Styles from './LockedStateModal.module.sass';

interface Props extends LockModal {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

export const LockedStateModal: React.FC<Props> = (props) => {
  const { visible, setVisible, ...lockModal } = props;
  const { title, description, ctaText, ctaAction } = lockModal;
  return (
    <Modal
      className={Styles.modal}
      visible={visible}
      centered
      closable
      maskClosable
      destroyOnClose
      footer={null}
      onCancel={() => setVisible(false)}
      title={
        <div className={Styles.title}>
          {title || (
            <>
              <LockFilled /> Locked Step
            </>
          )}
        </div>
      }>
      <div className={Styles.content}>{description}</div>
      <Row gutter={[0, 12]}>
        <Button
          className={Styles.ctaButton}
          type="primary"
          onClick={() => {
            setVisible(false);
            ctaAction();
          }}>
          {ctaText}
        </Button>
        <Button
          className={Styles.chatButton}
          icon={<MessageFilled />}
          onClick={() => {
            IntercomAPI.showWithMessagePreFilled(
              `Hey! I have questions about locked state with message: "${lockModal.description}"`,
              false,
            );
          }}>
          Chat with us
        </Button>
      </Row>
    </Modal>
  );
};
