import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { Moment } from 'moment';
import { from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  convertFirestoreTimestampFieldsToMoment,
  Environment,
  firestoreCollectionListener,
  firestoreDocumentListener,
  firestoreQueryListener,
  FirestoreReference,
} from '../../utils';
import { StepStatus } from '../CustomStep';
interface EngagementMetadata {
  consumptions: number; // could be views, reads, listens etc
  usersConsumed: {
    name: string;
    reference: firebase.firestore.DocumentReference;
  }[];
}

export enum ContentType {
  Webinar = 'Webinar',
  Video = 'Video',
  Article = 'Article',
  InteractiveFlow = 'InteractiveFlow',
  QnASnippet = 'QnASnippet',
  AudioFeed = 'AudioFeed',
}

export type StepLabel = {
  [key in StepStatus]: string | React.ReactElement;
};
export interface PredefinedStep {
  id: string;
  order: number;
  title: string;
  route: string;
  descriptionInHtml?: string;
  webinarId?: string;
  status?: StepStatus;
  deadline?: Moment;
  priority: number;
  label?: StepLabel;
}

export interface LockModal {
  title?: string | React.ReactElement;
  description: string | React.ReactElement;
  ctaText: string | React.ReactElement;
  ctaAction: () => void;
}

export interface StagePredefinedSteps {
  [key: string]: PredefinedStep;
}
export interface PredefinedSteps {
  [key: string]: StagePredefinedSteps;
}

export interface StepsStatus {
  [key: string]: any;
}

export interface Content {
  id: string;
  title: string; // Searchable attribute
  author?: {
    name: string;
    displayPictureUrl?: string;
    id: string;
  };
  associatedSteps: string[];
  engagementMetadata: EngagementMetadata;
  topics: {
    name: string; // Searchable attribute
    reference: firebase.firestore.DocumentReference;
  }[];
  associatedQuestions: firebase.firestore.DocumentReference[]; // Firestore Reference
  type: ContentType;
  createdAt: Moment;
  updatedAt: Moment;
}

// Webinars
export enum WebinarType {
  BigMarker = 'BigMarker',
}

export interface Webinar extends Content {
  webinarType: WebinarType;
  contentPictureUrl: string;
  description: string;
  agenda: string[];
  slots: {
    conferenceId: string;
    recordingId?: string;
    startTime: Moment;
    endTime: Moment;
  }[];
}

// Videos
export enum VideoType {
  YouTube = 'YouTube',
  Wistia = 'Wistia',
  VerticalInApp = 'VerticalInApp',
}

export interface Video extends Content {
  videoType: VideoType;
  videoId: string;
}

// Articles
export interface Article extends Content {
  attributionSource: {
    name: string;
    logoUrl: string;
  };
  imagePreviewUrl: string;
  description: string;
  link: string;
}

// Interactive Flows
export enum InteractiveFlowType {
  IntercomFlow = 'IntercomFlow',
  TypeformCUI = 'TypeformCUI',
  LandbotCUI = 'LandbotCUI',
}

export interface InteractiveFlow extends Content {
  flowType: InteractiveFlowType;
  link: string;
}

// QnASnippets
export interface QnASnippet extends Content {
  answer: string;
}

// Audio Files
export enum AudioFeedType {
  Podcast = 'Podcast',
  Interview = 'Interview',
}

export interface AudioFeed extends Content {
  audioType: AudioFeedType;
  link: string;
}

export const listenToPredefinedSteps = (): Observable<PredefinedSteps> => {
  return firestoreCollectionListener(FirestoreReference.PredefinedSteps(), true);
};

export const listenToStepsStatus = (uid: string): Observable<PredefinedSteps> => {
  return firestoreDocumentListener(FirestoreReference.UserStepsStatus(uid));
};

export const fetchContentItems = (): Observable<Content[]> => {
  return firestoreQueryListener(
    FirestoreReference.Content(),
    (snapshot) =>
      convertFirestoreTimestampFieldsToMoment({
        ...snapshot.data(),
        id: snapshot.id,
      }) as Content,
  );
};

export const listenToWebinar = (webinarId: string) => {
  return firestoreDocumentListener<Webinar>(FirestoreReference.Resources().doc(webinarId), (snapshot) =>
    convertFirestoreTimestampFieldsToMoment({
      ...snapshot.data(),
      id: snapshot.id,
    }),
  );
};

export const getEnterUrlForWebinar = (conferenceId: string, idToken: string): Observable<{ urlToEnter: string; chatURL: string }> => {
  return from(
    fetch(`${Environment.api.baseUrl}/webinars/bigMarker/enter`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: idToken,
      },
      body: JSON.stringify({ conferenceId }),
    }),
  ).pipe(
    switchMap((response: Response) => from(response.json()).pipe(map((json) => ({ json, ok: response.ok })))),
    map((response) => {
      if (response.ok) {
        return response.json;
      }
      throw new Error(response.json.message);
    }),
  );
};

export const markStepsStatus = (uid: string, route: string[], status: StepStatus | undefined, metadata?: { [key: string]: any }) => {
  const data: StepsStatus = route.reverse().reduce(
    (p, c): any => {
      return { [c]: p };
    },
    { status, ...metadata },
  );
  FirestoreReference.UserStepsStatus(uid).set(data, { merge: true });
};
